import classNames from 'classnames';
import {
  secondsInDay,
  secondsInHour,
  secondsInMinute,
  secondsInMonth,
  secondsInWeek,
  secondsInYear,
} from 'date-fns';
import { twMerge } from 'tailwind-merge';

export const cn = (...inputs: classNames.ArgumentArray) => {
  return twMerge(classNames(inputs));
};

export const formatAmount = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    useGrouping: true,
    // eslint-disable-next-line
    //@ts-ignore
    roundingMode: 'trunc',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(amount);
};

export const formatReward = (amount: number, minimumDigits = 2, maximumDigits = 2) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    useGrouping: true,
    // eslint-disable-next-line
    //@ts-ignore
    roundingMode: 'trunc',
    minimumFractionDigits: minimumDigits,
    maximumFractionDigits: maximumDigits,
  }).format(amount);
};

export const formatUsd = (usdAmount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    useGrouping: true,
    // eslint-disable-next-line
    //@ts-ignore
    roundingMode: 'trunc',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(usdAmount);
};

export const formatAmountCompact = (amount: number) => {
  return Intl.NumberFormat('en-US', { notation: 'compact' }).format(amount);
};

export const formatTimeAgo = (date: Date): string => {
  const ranges = [
    ['years', secondsInYear],
    ['months', secondsInMonth],
    ['weeks', secondsInWeek],
    ['days', secondsInDay],
    ['hours', secondsInHour],
    ['minutes', secondsInMinute],
    ['seconds', 1],
  ] as const;

  const secondsElapsed = (date.getTime() - Date.now()) / 1000;

  const [rangeUnit, rangeSeconds] =
    ranges.find(([, seconds]) => Math.abs(secondsElapsed) > seconds) || ranges[ranges.length - 1];

  const delta = secondsElapsed / rangeSeconds;

  return new Intl.RelativeTimeFormat('en').format(Math.round(delta), rangeUnit);
};
