'use client';

import { FC, PropsWithChildren, Reducer, useCallback, useReducer, useState } from 'react';

import { MODAL_ANIMATION_DURATION, ModalOption } from '@uikit';

import { MissingFieldsEntry, RequestSignResult } from '@api';

import useGlobalState from '@shared/common/providers/GlobalStateProvider';
import { createSafeContext, useSafeContext } from '@shared/helpers/context';
import { RequestSignProps } from '@shared/hooks/web3/useWeb3';

export const GlobalModalType = {
  Auth: 'Auth',
  CompleteMagicId: 'CompleteMagicId',
  EmailLoginError: 'EmailLoginError',
  ConnectAndSign: 'ConnectAndSign',
  RateLimited: 'RateLimited',
  Banxa: 'Banxa',
  CustomContent: 'CustomContent',
  Support: 'Support',
} as const;

const initialGlobalModalsState: Record<keyof typeof GlobalModalType, boolean> = {
  [GlobalModalType.Auth]: false,
  [GlobalModalType.Banxa]: false,
  [GlobalModalType.CompleteMagicId]: false,
  [GlobalModalType.ConnectAndSign]: false,
  [GlobalModalType.CustomContent]: false,
  [GlobalModalType.EmailLoginError]: false,
  [GlobalModalType.RateLimited]: false,
  [GlobalModalType.Support]: false,
};

type GlobalModalsState = typeof initialGlobalModalsState;
type GlobalModalsAction = [type: keyof GlobalModalsState, value: boolean];

const globalModalsReducer: Reducer<GlobalModalsState, GlobalModalsAction> = (
  state,
  [type, value],
) => {
  switch (type) {
    case GlobalModalType.Auth:
    case GlobalModalType.Banxa:
    case GlobalModalType.CompleteMagicId:
    case GlobalModalType.ConnectAndSign:
    case GlobalModalType.CustomContent:
    case GlobalModalType.EmailLoginError:
    case GlobalModalType.RateLimited:
    case GlobalModalType.Support:
      return {
        ...state,
        [type]: value,
      };
    default:
      throw Error('Unknown action: ' + type);
  }
};

interface GlobalModalsController {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
  setIsOpen: (value: boolean) => void;
  props: Pick<
    ModalOption,
    'isOpen' | 'onOpenChange' | 'isClosing' | 'setIsClosing' | 'isAnimationEnabled'
  >;
}

const GlobalModalsContext = createSafeContext<{
  state: GlobalModalsState;
  createController: (modalName: keyof GlobalModalsState) => GlobalModalsController;
}>();

const GlobalModalsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(globalModalsReducer, initialGlobalModalsState);

  const [isClosing, setIsClosing] = useState<Record<keyof typeof GlobalModalType, boolean>>({
    [GlobalModalType.Auth]: false,
    [GlobalModalType.Banxa]: false,
    [GlobalModalType.CompleteMagicId]: false,
    [GlobalModalType.ConnectAndSign]: false,
    [GlobalModalType.EmailLoginError]: false,
    [GlobalModalType.RateLimited]: false,
    [GlobalModalType.CustomContent]: false,
    [GlobalModalType.Support]: false,
  });

  const createController = (modalName: keyof GlobalModalsState): GlobalModalsController => {
    const [isOpen, setIsOpen] = [
      state[modalName],
      (value: boolean) => dispatch([modalName, value]),
    ];

    const controller = {
      open: () => setIsOpen(true),
      close: () => {
        setIsClosing((prev) => ({ ...prev, [modalName]: true }));

        setTimeout(() => {
          setIsOpen(false);
          setIsClosing((prev) => ({ ...prev, [modalName]: false }));
        }, MODAL_ANIMATION_DURATION);
      },
      toggle: () => setIsOpen(state[modalName]),
      setIsOpen,
    };

    const props = {
      isOpen,
      onOpenChange: (value: boolean) => (value ? controller.open() : controller.close()),
      isClosing: isClosing[modalName],
      setIsClosing: (value: boolean) => setIsClosing((prev) => ({ ...prev, [modalName]: value })),
      // Needed because useModal has own animation enabled it creates animation bug when modal closes -> opens and then disappears
      isAnimationEnabled: false,
    };

    return {
      isOpen,
      ...controller,
      props,
    };
  };

  return (
    <GlobalModalsContext.Provider value={{ state, createController }}>
      <>{children}</>
    </GlobalModalsContext.Provider>
  );
};

export default GlobalModalsProvider;

export const useGlobalModalsController = (
  modalName: keyof GlobalModalsState,
): GlobalModalsController => {
  const { createController } = useSafeContext(GlobalModalsContext, 'GlobalModalsContext');

  return createController(modalName);
};

export const useAuthModal = () => useGlobalModalsController(GlobalModalType.Auth);

export const useEmailLoginError = () => useGlobalModalsController(GlobalModalType.EmailLoginError);

export const useRateLimited = () => useGlobalModalsController(GlobalModalType.RateLimited);

export const useBanxaModal = () => {
  const modal = useGlobalModalsController(GlobalModalType.Banxa);
  const { value, setValue } = useGlobalState('banxa');

  const open = useCallback(
    (params: Parameters<typeof setValue>[0] | undefined = {}) => {
      setValue({
        ...value,
        ...(params && params),
      });

      modal.open();
    },
    [modal, value, setValue],
  );
  return {
    ...modal,
    open,
  };
};

export const useSupportModal = () => useGlobalModalsController(GlobalModalType.Support);

export const useCustomContentModal = () => {
  const modal = useGlobalModalsController(GlobalModalType.CustomContent);
  const { value, setValue } = useGlobalState('customContent');

  const open = useCallback(
    (params: Parameters<typeof setValue>[0] | undefined = { content: null }) => {
      setValue({
        ...value,
        ...(params && params),
      });

      modal.open();
    },
    [modal, value, setValue],
  );
  return {
    ...modal,
    open,
  };
};

export const useCompleteMagicIdModal = () => {
  const modal = useGlobalModalsController(GlobalModalType.CompleteMagicId);
  const { setValue: setMissingFields } = useGlobalState('missingFieldsEntry');

  const open = useCallback(
    (missingFields?: MissingFieldsEntry | null) => {
      if (missingFields) {
        setMissingFields(missingFields);
      }

      modal.open();
    },
    [modal, setMissingFields],
  );

  return {
    ...modal,
    open,
  };
};

export const useConnectAndSignModal = () => {
  const { value: confirmData, setValue: setConfirmData } = useGlobalState(
    'connectAndSignConfirmCallback',
  );
  const modal = useGlobalModalsController(GlobalModalType.ConnectAndSign);

  const open = useCallback(
    (
      confirmCallback: (props?: RequestSignResult) => Promise<void | any>,
      params:
        | {
            confirmStepTitle?: string;
            connectStepTitle?: string;
            confirmButtonText?: string;
            setIsLoading?: (value: boolean) => void;
            signProps?: Omit<RequestSignProps, 'skipConnectStep' | 'nonce'>;
          }
        | undefined = {},
    ) => {
      setConfirmData({
        ...confirmData,
        ...(params && params),
        confirmCallback,
      });

      modal.open();
    },
    [modal, confirmData, setConfirmData],
  );

  return {
    ...modal,
    open,
  };
};
